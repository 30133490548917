<template>
	<main id="liste" v-cloak>
		<HeaderTab :title="$t('compta.export.title_page')"/>

		<div id="content">
            <div class="container-fluid">
            	<ExportConfig></ExportConfig>
            </div>
        </div>
	</main>
</template>

<script>
	export default {
		components: {
            HeaderTab: () => import('@/components/HeaderTab'),
            ExportConfig: () => import('@/components/Accounting/ExportConfig'),
        }
    }

</script>